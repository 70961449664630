<!--
 * @Descripttion: 模型列表页面
 * @Author: zhu
 * @Date: 2024-8-22
 * @LastEditors: zhu
 * @LastEditTime: 2024-8-22
--> 


<template>
  <div class="about" id="about" name="about">
    <div class="about-layout">
      <el-row :gutter="10">
        <div>
          <CenterMenu></CenterMenu>
        </div>
        <el-col :span="20">
          <div class="about-content">
            <div class="extra" style="background: #f5f5f5;"></div>
			<div class="list">
				<div class="cmsNav" v-if="moduleTitle">首页 > {{ moduleTitle }}</div>
				<div v-if="loading" class="loading-cms" style="font-size: 32px">
					<i class="el-icon-loading"></i>
				</div>
				<template v-else>
					<router-link v-for="(item, index) in list" :to="{ path: '/article', query: {id:item.id} }" :key="index">
						<div class="li">
							<span>{{ item.title }}</span><span style="margin-left: 12px;font-size: 14px; color: #aaa;">{{ item.updated_at | filterDate }}</span>
						</div>
					</router-link>
				</template>
			</div>
			<!-- 分页 -->
			<div class="pagination">
				<el-pagination
					background
					@current-change="handleCurrentChange"
					:page-size="limit"
					layout="total, prev, pager, next, jumper"
					:total="total"
				></el-pagination>
			</div>
			<!-- 分页END -->
            <div class="extra1"></div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import CenterMenu from '../components/CenterMenu'
import * as noticeAPI from '@/api/notice'
export default {
  name: 'About',
  data() {
    return {
		list: [],
		tags: '',
		page: 1,
		limit: 10,
		total: 0,
		loading: true,
		moduleTitle: ''
	}
  },
  components: {
    CenterMenu
  },
	filters: {
		filterDate(value) {
			return value.split(' ')[0]
		}
	},
	created() {
		if (this.$route.query.tags != undefined) {
			this.tags = this.$route.query.tags
		}
		this.total = 0
		this.getData()
	},
	methods: {
		getData() {
			this.loading = true
			noticeAPI
			.showCmsList({ tags: this.tags, page: this.page, limit: this.limit })
			.then(res => {
				if (res.status_code === 200) {
					this.list = res.data.list.data
					this.total = res.data.list.total
					this.moduleTitle = res.data.module.label
				} else {
					this.notifyError('获取列表失败', res.message)
				}
				this.loading = false
			})
			.catch(err => {
				this.loading = false
				this.notifyError('获取列表失败', err)
			})
		},
		handleCurrentChange(val) {
			this.page = val // val 页面
			this.getData()
		}
	}
}
</script>
<style scoped>
.about-layout {
  max-width: 1225px;
  margin: 0 auto;
}
.about-content {
  background-color: #ffffff;
  margin-bottom: 30px;
}

.extra {
  height: 10px;
}
.extra1 {
  height: 30px;
}
.list{
	padding-top: 30px;
	padding-left: 30px;
}
.li {
	color: #666;
	line-height: 26px;
	font-size: 15px;
	position: relative;
	padding-left: 15px;
}
.li:hover {
	color: rgb(255, 103, 0);
	cursor: pointer;
}
.li:before {
	content: '•';
	position: absolute;
	left: 0;
	top: 0;
}
.pagination {
	padding-top: 15px;
	text-align: center;
}
.loading-cms{
	text-align: center;
	color: #666;
	padding: 10px 0 10px;
}
.cmsNav {
	height: 30px;
	line-height: 30px;
	padding-bottom: 18px;
}
</style>